import React, { useState } from 'react';
import CooperationPopup from '../CooperationPopup/CooperationPopup';
import "./Hero.css"

const Hero = () => {
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <section className='hero-container'>
      <div className='hero-text'>
        <h1>快速搭建 AI 知识助手</h1>
        <h3 className='subheading'>调用 API 接口快速搭建自己的私域知识问答助手</h3>
        <h3 className='subheading'>注册即送 30 次免费问答体验额度</h3>
      </div>
      <div className='hero-examples'>
        <div className='example'>
          <img src="./wechat.svg" alt="logo" width={40} />
          <h3>接入微信公众号</h3>
        </div>
        <div className='example'>
          <img src="./browser.png" alt="logo" width={40} />
          <h3>接入自有网站</h3>
        </div>
        <div className='example'>
          <img src="./facebook.svg" alt="logo" width={40} />
          <h3>接入Facebook</h3>
        </div>
      </div>
      <button className='hero-button' onClick={togglePopup}>
        合作咨询
      </button>
      {showPopup && <CooperationPopup onClose={togglePopup} />}
    </section>
  );
};

export default Hero
